
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700&display=swap");

.main-admin {
    height: 100vh;
}
hr {
  display: block;
  height: 1px;
  width: auto;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}
.sidebar {
  background-color:  #193441;
  border-right: 1px solid #ececec;
    .sidebar-logo {
      display: grid;
      color: white;
        text-align: center;
        margin: 30px 7px 45px 0;
    }
}
.sidebar-container {
    .nav {
        list-style: none;
        padding-left: 0;
        .nav-item {
            margin: 3px 0;
            width: 100%;
            a.nav-link {
                font-size: $font-size13;
                color: white;
                width: 100%;
                height: 50px;
                display: flex;
                align-items: center;
                padding: 0;
                font-weight: $font-semibold;
                border-radius: 0 10px 10px 0;
                cursor: pointer;
            }
            span {
                padding: 0 16px;
            }
            
        }
    }
}
.signout{
  position: fixed;  
  bottom: 0;
  }
.right-sidebar {
    padding: 30px !important;
}

