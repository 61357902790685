@import './var';

.form-floating {
  margin-bottom: 20px;
  .form-control , .form-select {
    color: $heading-color;
    font-size: $font-size15;
    font-weight: $font-medium;
    border-radius: 10px;
    padding-left: 20px;
    border: 1px solid #e9e9e9;
    &:focus {
      border-color: #333;
      outline: 0;
      box-shadow: unset;
    }
  }
  label {
      font-size: $font-size13;
      color: $text-color;
      font-weight: $font-reg;
      padding-left: 20px;
  }
  select {
    option {
      font-size: 14px;
      &:hover {
        background: rgba(0, 0, 0, 0.3);
        color: #fff;
      }
    }
  }
}

.link {
  color: $heading-color;
  text-decoration: underline;
  font-size: $font-size14;
}



.toast-container .ngx-toastr {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 400px!important;
  right: 107px;
  height: 55px;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  border-radius: 10px !important;
  @media (max-width: 576px) {
    width: 366px !important;
    right: 0;
  }
  &:hover {
    box-shadow: 0 0 12px #00000036;
  }
}
.toast-error {
  background-color:#feecec!important;
  color: #333!important;
  background-image: url('../icons/error-icon.svg')!important;
}
.toast-success {
  background-color:#e5f6ef!important;
  color: #333!important;
  background-image: url('../icons/success-icon.svg')!important;
}
.ng-trigger {
  position: relative;
  &::before {
    content: '';
    background-image: url('../icons/message-cross-icon.svg')!important;
    right: 10px;
    top: 50%;
    position: absolute;
    width: 21px;
    height: 21px;
    transform: translatey(-50%);
  }
}
.owl-carousel {
  .ng-trigger {
      position: relative;
      &::before {
        content: '';
        display: none !important;
      }
  }
}
.show-password {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

