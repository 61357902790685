
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
@import 'var';
@import 'mixin';
@import 'auth';
@import 'forms';
@import 'button';
@import 'sidebar';
@import 'datepicker';
@import "dashboard";
@import "common";
@import "table";
@import "fonts";
@import "userfeedback";

 body {
  font-family: 'Source Sans 3', sans-serif;
 }
