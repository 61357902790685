*{box-sizing: border-box;}
.pl-15 {
    padding-left: 15px !important;
}
.pl-20 {
    padding-left: 20px !important;
}
.pl-40 {
    padding-left: 40px !important;
}
.pr-15 {
    padding-right: 15px !important;
}
.pr-40 {
    padding-right: 40px !important;
}
.pt-15 {
    padding-top: 15px !important;
}
.pt-20 {
    padding-top: 20px !important;
}
.pt-30 {
    padding-top: 30px !important;
}

.mt-5 {
    margin-top: 5px !important;
}
.mt-10 {
    margin-top: 10px !important;
}
.mt-15 {
    margin-top: 15px !important;
}
.mt-20 {
    margin-top: 20px !important;
}
.mt-25 {
    margin-top: 25px !important;
}
.mt-30 {
    margin-top: 30px !important;
}

.ml-5 {
    margin-left: 5px !important;
}
.ml-10 {
    margin-left: 10px !important;
}
.ml-15 {
    margin-left: 15px !important;
}
.ml-20 {
    margin-left: 20px !important;
}
.ml-25 {
    margin-left: 25px !important;
}
.ml-30 {
    margin-left: 30px !important;
}

.mr-5 {
    margin-right: 5px !important;
}
.mr-10 {
    margin-right: 10px !important;
}
.mr-15 {
    margin-right: 15px !important;
}
.mr-20 {
    margin-right: 20px !important;
}
.mr-25 {
    margin-right: 25px !important;
}
.mr-30 {
    margin-right: 30px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}
.mb-10 {
    margin-bottom: 10px !important;
}
.mb-20 {
    margin-bottom: 15px !important;
}
.mb-15 {
    margin-bottom: 15px !important;
}
.mb-30 {
    margin-bottom: 30px !important;
}
.mb-40 {
    margin-bottom: 40px !important;
}
.mb-50 {
    margin-bottom: 50px !important;
}
.width47 {
    width: 47%;
}
.width-290 {
    width: 290px;
}
.width-360 {
    width: 360px;
}
.common-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-side {
        display: flex;
        .page-back {
            a {
                text-decoration: none;
                margin-right: 12px;
                img {
                    width: 24px;
                    height: 24px;
                    background-color: #f0f0f0;
                    padding: 5px;
                    border-radius: 5px;
                }
            }
        }
        .main-heading {
            margin-bottom: 0;
            font-size: $font-size22;
            font-weight: $font-bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.27;
            letter-spacing: normal;
            color: $heading-color;
        }
        .sub-title {
            margin: 5px 0 0 0;
            font-size: $font-size15;
            line-height: normal;
            color: $text-color;
        }
    }
    .right-side {
        display: flex;
    }
}

.admin-heading-subdetail {
    margin: 30px 0 25px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .admin-store-inline {
        display: flex;
    }
    .subdetail-content {
        display: flex;
        border-right: 1px solid #d8d8d8;
        padding: 0 30px;
        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
            border-right: 0;
        }
        span {
            font-size: 15px;
            color: $heading-color;
        }
        h6 {
            font-size: 18px;
            font-weight: 800;
            color: $heading-color;
            margin-bottom: 0;
            margin-left: 6px;
        }
    }
}

.monthly-dropdown {
    button {
        background-color: transparent;
        border: 0;
        font-size: $font-size12;
        font-weight: $font-medium;
        color: $text-color;
        margin: 0;
        padding: 0;
        position: relative;
        top: -3px;
    }
    .dropdown-menu {
        border-radius: 10px;
        border: 0;
        box-shadow: 1px 4px 10px 0 rgb(0 0 0 / 7%);
        background-color: $white-color;
        padding: 20px 0 15px 0;
        li {
            margin: 0 20px;
            .sort-by-heading {
                font-size: 12px;
                text-transform: uppercase;
                font-weight: 700;
                margin-bottom: 10px;
            }
            .dropdown-item {
                font-size: $font-size14;
                line-height: normal;
                letter-spacing: normal;
                color: $heading-color;
                border-bottom: 1px solid #e9e9e9;
                padding: 10px 0;
                &:hover {
                    background-color: transparent;
                }
            }
            &:last-child {
                .dropdown-item {
                    border: 0;
                }
            }
        }
    }
}

.common-search {
    .nosubmit {
        border-radius: 5px;
        border: none;
        padding: 0;
        margin: 0 10px;
      }
      input[type="search"] {
        margin: 0;
        font-size: $font-size14;
        padding: 0 4px 0 50px;
        width: 300px;
        height: 45px;
        border-radius: 10px;
        border: 0;
        background: url('../icons/search-icon.svg') no-repeat 16px center;
        padding-right: 10px !important;
      }
      input[type="search"]::placeholder {
        opacity: 0.5;
        font-size: 20px;
        line-height: normal;
        letter-spacing: normal;
        color: #193441;
        font-weight: $font-medium;
      }
      input[type="search"]:focus {
        box-shadow: unset;
        border-color: unset;
        outline: none;
    }
}
.three-dot-dropdown {
    .dropdown-menu {
        padding: 24px;
        border: 0;
        border-radius: 10px;
        box-shadow: 1px 4px 10px 0 rgb(0 0 0 / 7%);
        background-color: $white-color;
        &.show {
            inset: 30px 0 auto auto !important;
        }
        &.pos-from-top {
            &.show {
                inset: 50px 0 auto auto !important;
            }
        }        
        li {
            margin-bottom: 20px;
            cursor: pointer;
            &:last-child {
                margin-bottom: 0;
            }
            .dropdown-item {
                font-size: $font-size13;
                font-weight: $font-medium;
                line-height: normal;
                color: $bg-secondary;
                padding: 0;
                display: flex;
                align-items: center;
                &.text-edit {
                    color: #6f6f6f;
                }
                &:hover {
                    background-color: transparent;
                }
                span {
                    padding-right: 16px;
                }
                .active {
                    font-size: 13px;
                    font-weight: 500;
                    line-height: normal;
                    color: #008b1a;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    img {
                        padding-right: 16px;
                    }
                }
                .inactive {
                    font-size: 13px;
                    font-weight: 500;
                    line-height: normal;
                    color: #ff0000;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    img {
                        padding-right: 16px;
                    }
                }
            }
        }
    }
    .table-txt {
        color: #193441;
        font-size: 14px;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 0;
        background-color: transparent;
        border: 0;
        overflow-wrap: anywhere;
        &::after {
            display: none;
        }
    }
}
.admin-pagination {
    display: flex;
    justify-content: flex-end;
    margin: 30px 0 0px 0;
    .pagination {
        margin-bottom: 0;
        margin-left: 20px;
        .page-item {
            padding: 0;
            margin: 0;
            cursor: pointer;
            &:first-child {
                .page-link {
                    padding: 0 5px;
                    margin: 0;
                    &:focus {
                        outline: 0;
                        box-shadow: none;
                    }
                }
            }
            &:last-child {
                .page-link {
                    padding: 0 5px;
                    margin: 0;
                }
            }
            .page-link {
                border: 0;
                background-color: transparent;
                font-size: $font-size14;
                font-weight: $font-medium;
                margin: 0;
                padding: 1px 8px;
                color: $text-color;
                &.active {
                    border-radius: 5px;
                    background-color: $text-color;
                    color: $white-color;
                }
            }
        }
    }
    nav {
      display: flex;
      align-items: center;
      .approx-count {
        color: #5c5a5a;
        span {
          font-size: 14px;
          padding: 0 4px;
        }
      }
    }
}
.modal {
    .modal-content {
        border-radius: 10px;
        background-color: #f8f8f8;
        border: 0;
        .modal-header {
            border-bottom: 0;
            padding: 25px 25px 0 25px;
            .modal-title {
                svg {
                    position: relative;
                    top: -7px;
                    left: 2px;
                }
            }
            .btn-close {
                background: unset;
                &:focus {
                    outline: 0;
                    box-shadow: unset;
                }
            }
        }
        .modal-body {
            padding: 20px 25px;
        }
        .modal-footer {
            justify-content: center;
            border-top: 0;
            padding: 0 25px 25px 25px;
        }
    }
}
.store-edit-modal {
    .modal-body {
        height: 400px;
    }
    .modal-header {
        .btn-close {
            img {
                position: relative;
                top: -4px;
                right: 5px;
            }
        }
    }
}
.delete-modal-width {
    max-width: 345px !important;
    .change-password-close {
        border: 0;
        background-color: unset;
        padding: 0;
        img {
            font-size: 20px;
        }
    }
}
.perfume-edit-modal {
    max-width: 360px !important;
    .change-password-close {
        border: 0;
        background-color: unset;
        padding: 0;
        img {
            font-size: 20px;
        }
    }
    .after-upload {
        width: 85px;
        height: 85px;
        padding: 10px 13.7px 10px 13px;
        border-radius: 10px;
        border: solid 1px #e9e9e9;
        background-color: #fff;
    }
    .upload-text {
        margin: 0 0 0 16px;
        font-size: 12px;
        text-align: center;
        color: #193441;
        font-weight: bold;
        text-decoration: underline;
        border: 0;
        background: transparent;
        text-transform: uppercase;
    }
}
.modal-btn-height {
    height: 35px;
    border-radius: 5px;
}
.border-radius10 {
    border-radius: 10px !important;
}
.filter-button {
    border-top: 1px solid #d8d8d8;
    .clear-all {
        border: 0;
        border-right: 1px solid #d8d8d8;
        background-color: transparent;
        width: 50%;
        padding: 15px 0;
        font-size: 12px;
        color: #666666;
        font-weight: 800;
        text-transform: uppercase;
        height: 50px;
    }
    .apply-filter {
        border: 0;
        background-color: transparent;
        width: 50%;
        padding: 10px 0;
        font-size: 12px;
        color: #202020;
        font-weight: 800;
        text-transform: uppercase;
        height: 50px;
    }
}
.custom-checkbox {
    margin: 20px 0 30px 0;
    .unstyled {
      list-style: none;
      padding-left: 0;
      li {
          position: relative;
          margin-bottom: 15px !important;
      }
    }
    .styled-checkbox {
      position: absolute;
      opacity: 0;
      &+label {
        position: relative;
        cursor: pointer;
        padding: 0;
        color: #333333;
        font-size: 14px;
      }
      &+label:before {
        content: '';
        margin-right: 15px;
        display: inline-block;
        vertical-align: text-top;
        width: 20px;
        height: 20px;
        border: 2px solid #727272;
        border-radius: 1px;
        background: white;
      }
      &:checked+label:before {
        background: #202020;
        border-color: #202020;
      }
      &:disabled+label {
        color: #b8b8b8;
        cursor: auto;
      }
      &:disabled+label:before {
        box-shadow: none;
        background: #ddd;
        border: 2px solid #202020;
      }
      &:checked+label:after {
        content: '';
        position: absolute;
        left: 5px;
        top: 11px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow:
          2px 0 0 white,
          4px 0 0 white,
          4px -2px 0 white,
          4px -4px 0 white,
          4px -6px 0 white,
          4px -8px 0 white;
        transform: rotate(45deg);
      }
      &:checked {
        label {
          &::after {
            border: 2px solid #202020;
          }
        }
      }
    }
  }

/*  The ul will have display:none by default */
.user-filter {
    position: relative;
    .dropdown-toggle {
        background-color: #fff;
        width: 45px;
        height: 45px;
        border-radius: 10px;
    }
    ul {
        margin:0;
        padding:0;
        li {
            a {
                color: inherit;
                text-decoration: none;
            }
        }
    }
    #checkbox-toggle {
        display: none;
    }
    input[type=checkbox]:checked ~ ul {
        display: block;
    }
    .css-filter-icon {
        background-color: #fff;
        width: 45px;
        height: 45px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
            cursor: pointer;
        }
    }
    .dropdown-menu {
        padding: 0;
        right: 0 !important;
        li {
            margin-bottom: 0 !important;
        }
        .filter-search {
            .common-search {
                .nosubmit {
                    border-radius: 10px;
                    border: none;
                    padding: 0;
                    margin: 0;
                    input[type=search] {
                        width: 100%;
                        padding-left: 50px;
                    }
                }
            }
            .filter-by-heading {
                font-size: 12px;
                font-weight: 700;
                text-transform: uppercase;
            }
        }
    }
}
.star-rating {
    margin-top: 15px;
    .bs-rating-star {
        color: #808080;
        margin: 0 3px;
        font-size: $font-size13;
        &.active {
            color: #f0b60a;
        }
    }
}
.owl-nav {
    display: none;
}
.owl-dots {
    width: 90px;
    height: 24px;
    padding: 0 12px 0 12px;
    border-radius: 15px;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    margin: 20px auto !important;
    background-color: #fff;
    display: flex;
    align-items: center;
    .owl-dot {
        span {
            background: #00000038 !important;
        }
        &.active {
            span {
                background: #193441 !important;
            }
        }
    }
}
.get-your-code {
    .modal-content {
        padding: 30px;
        h4 {
            margin-bottom: 0;
        }
    }
}
.dot-dot {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.action-btn-center {
    justify-content: center;
    width: 100%;
}
.empty-perfume {
    text-align: center;
    margin: 150px 0 150px 0;
    h6 {
        margin: 20px 0 8px 0;
        font-size: 18px;
        font-weight: 500;
        color: #193441;
    }
    p {
        opacity: 0.7;
        font-size: 13px;
        color: #193441;
        margin-bottom: 0;
    }
}
.text-underline {
    text-decoration: underline !important;
}
.hand-cursor {
    cursor: pointer;
}
.cursor-pointer {
    cursor: default;
}
.fixed-filter-height {
    height: 250px;
    overflow-y: scroll;
    .unstyled {
        label {
            text-overflow: ellipsis;
            overflow-x: hidden;
            white-space: nowrap;
            width: 100%;
        }
    }
}
.fliter-popup-close {
    display: none !important;
}
.fliter-popup-open {
    display: block;
}
.dashboard-chart-bg {
    .chart-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        .chart-dropdown {
        display: flex;
            select {
                width: 120px;
                font-size: 14px;
                text-transform: capitalize;
                font-weight: 600;
                color: #666666;
                &:focus {
                    outline: 0;
                    box-shadow: none;
                }
                &:last-child {
                    width: 180px;
                    margin-left: 15px;
                }
            }
        }
        .chart-des {
            .chart-count {
                color: #202020;
                font-size: 26px;
                font-weight: bold;
            }
            .chart-perfume {
                font-size: 14px;
                text-transform: capitalize;
                font-weight: 600;
                color: #666666;
            }
        }
    }
}
.chart-margin-from-top {
    #google-visualization-errors-all-3{
        display: none !important;
    }
    #google-visualization-errors-all-1{
        display: none !important;
    }
    #google-visualization-errors-all-2{
        display: none !important;
    }
    #google-visualization-errors-all-4{
        display: none !important;
    }
    #google-visualization-errors-all-5{
        display: none !important;
    }
    #google-visualization-errors-all-6{
        display: none !important;
    }
    #google-visualization-errors-all-7{
        display: none !important;
    }
    #google-visualization-errors-all-8{
        display: none !important;
    }
    #google-visualization-errors-all-9{
        display: none !important;
    }
    #google-visualization-errors-all-10{
        display: none !important;
    }
    #google-visualization-errors-all-11{
        display: none !important;
    }
    #google-visualization-errors-all-12{
        display: none !important;
    }
    #google-visualization-errors-all-0{
        display: none !important;
    }
    margin-top: 60px;
}
.dashboard-space-from-top {
    margin-top: 30px !important;
}
::-webkit-scrollbar {
  width: 5px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

::-webkit-scrollbar-thumb {
  background: rgb(32, 32, 32) !important;
  border-radius: 5px !important;
}

::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}
