$grey: #7B7B7B;
$text-color: #666666;
$text-admin-color: #8f8f8f;
$heading-color: #333;
$bg-primary: #202020;
$bg-secondary: #ff0000;
$white-color: #fff;
$font-lt: 300;
$font-reg: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 800;
$auth-heading: 22px;
$font-size12: 12px;
$font-size13: 13px;
$font-size14: 14px;
$font-size15: 15px;
$font-size22: 22px;
$font-size25: 25px;
