.btn {
  height: 45px;
  font-size: $font-size13;
  color: #193441;
  border-radius: 3px;
  text-align: center;
  font-weight: $font-lt;

  &.wide {
    width: 100%;
  }
  &.primary {
   width: 100%;
  }
  &:focus,
  &:active {
    outline: 0;
    box-shadow: none;
  }
  &.default:hover,
  &.default {
    background: #7A7E7C;
    color: $white-color;
  }
  &.primary:hover,
  &.primary {
    border: 1px solid #000;
    background: #ffffff;
    color: #193441;
    box-shadow: unset;
    outline: 0;
    // font-weight: 600;
  }
  &.admin-btn {
    float: right;
    background: #193441;
    color: #FCFFF5;
    font-size: $font-size12;
    padding: 0 25px;
    font-weight: 800;
    text-transform: uppercase;
    &:hover {
      color: $white-color;
    }
    &:focus {
      box-shadow: unset;
      outline: 0;
    }
  }
  &.delete-btn {
    background: $bg-secondary;
    color: $white-color;
    font-size: $font-size12;
    padding: 0 25px;
    font-weight: 800;
    text-transform: uppercase;
    &:hover {
      color: $white-color;
    }
    &:focus {
      box-shadow: unset;
      outline: 0;
    }
  }
  &.grey:hover,
  &.grey {
    background: #F4F7F9;
    color: #A2A2A2;
  }
  &.secondary:hover,
  &.secondary {
    background: #4EAC9F;
    color: $white-color;
  }
  &.medium {
    padding: 6px 0;
    height: 32px;
  }
  &.delete {
    background: #EC7979;
  }
}
