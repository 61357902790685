.bs-datepicker-container {
  padding: 0;
  box-shadow: 0 3px 6px #00000012;
  border-radius: 8px;
}

bs-datepicker-container{
 z-index:100;
}

.bs-datepicker-body {
  border: 0 !important;
  padding: 0 10px 10px;
  table {
    th {
      padding-bottom: 10px;
      font-weight: 600;
      text-align: center;
      color: #6C6C6C;
    }
    td {
      color: #6C6C6C;
    }
  }
}
.bs-datepicker {
  box-shadow: none !important;
  border-radius: 8px;
  .selected {
    background: #4eac9f !important;
  }
  .days,
  .weeks {
    tr {
      th,td {
        &:nth-child(1) {
          display: none !important;
        }
      }
    }
  }
}
